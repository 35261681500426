import React from "react"
import { ContentFilterProvider } from "./ContentFilter"
import { shortcodes } from "./shortcodes"
import { MDXProvider } from "@mdx-js/react"
import { PageProps } from "gatsby"

export const LayoutRoot: React.FC<
  PageProps<unknown, unknown, { children: React.ReactNode } | undefined>
> = (props) => (
  <MDXProvider components={shortcodes}>
    <ContentFilterProvider uri={props.uri}>
      {props.children}
    </ContentFilterProvider>
  </MDXProvider>
)
