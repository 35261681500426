import * as React from "react"

export const Unpublished = () => (
  <strong
    className="n-border-accent n-color-black n-type-smallest"
    style={{
      display: "inline-block",
      padding: 2,
      marginLeft: 10,
      lineHeight: 1,
      verticalAlign: "middle",
      fontStyle: "normal",
    }}
  >
    UNPUBLISHED
  </strong>
)
