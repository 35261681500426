module.exports = (i) => ({
  ...i,
  snippet: {
    ...i.snippet,
    title: i.snippet.title.replace("Sound Redesign — ", ""),
    description: i.snippet.description
      .replace(/\n\n?sounddesign.marcohamersma.com\n\n?/g, "")
      .replace(/\n\n?marcohamersma.com\/sound\n\n?/g, "")
      .split("\n\n")[0],
  },
})
