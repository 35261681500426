import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import { contentFilters } from "./ContentFilter"

export const AboutTeaser: React.FC<{
  contentFilter?: contentFilters
}> = (props) => {
  const { bio } = useStaticQuery(graphql`
    query {
      bio: mdx(slug: { eq: "bio" }) {
        fields {
          excerpt
        }
      }
    }
  `)

  return (
    <>
      <ReactMarkdown>{bio.fields.excerpt}</ReactMarkdown>

      <Link
        className="button n-spacing-top-small"
        to="/about"
        state={{ contentFilter: props.contentFilter }}
      >
        More about me…
      </Link>
    </>
  )
}
