// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
import "./src/css/index.scss"
import { LayoutRoot } from "./src/components/LayoutRoot"
import { AutoContentFilter } from "./src/components/AutoContentFilter"
import React from "react"

// This component won't be unmounted, it's persistant, and will receive updates
// for page changes, neat huh?
class FancyComponent extends React.PureComponent {
  setupLightbox() {
    import("luminous-lightbox").then(({ LuminousGallery }) => {
      new LuminousGallery(
        document.querySelectorAll(
          ".gatsby-resp-image-link, .workItem__image__link"
        ),
        {},
        {
          injectBaseStyles: false,
          caption: function (node) {
            if (node.dataset.summary) return node.dataset.summary
            try {
              return node.querySelector("img").title
            } catch (e) {
              return null
            }
          },
        }
      )
    })
  }

  componentDidMount() {
    this.setupLightbox()
  }

  componentDidUpdate() {
    this.setupLightbox()
  }

  render() {
    return null
  }
}

export const wrapPageElement = ({ element, props }) => (
  <LayoutRoot {...props}>
    <AutoContentFilter {...props} />
    <FancyComponent {...props} />
    {element}
  </LayoutRoot>
)
