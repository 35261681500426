import * as React from "react"

export const ClientOnly: React.FC<{ children: React.ReactNode }> = (props) => {
  const [showEmail, setShowEmail] = React.useState(false)

  React.useEffect(() => {
    setShowEmail(true)
  }, [])

  return showEmail ? <>{props.children}</> : null
}
