import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { WorkItem } from "./WorkItem"

export const ProjectHighlights: React.FC = (props) => {
  const { projects } = useStaticQuery(graphql`
    query ($publishedFilter: BooleanQueryOperatorInput) {
      projects: allMdx(
        sort: { fields: [frontmatter___order], order: ASC }
        filter: {
          fields: { contentType: { eq: "work" } }
          frontmatter: { published: $publishedFilter, highlight: { eq: true } }
        }
      ) {
        edges {
          node {
            ...MHWorkItem
          }
        }
      }
    }
  `)

  return (
    <ul className="n-type-small n-spacing-bottom-medium">
      {projects.edges.map(({ node }, i) => (
        <WorkItem
          node={node}
          key={node.id}
          eager={i === 0}
          className="n-vspacing-medium"
        />
      ))}
    </ul>
  )
}
