import { Link } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { BrowserFrame } from "./BrowserFrame"
import { Unpublished } from "./Unpublished"
import classNames from "classnames"

export const WorkItem: React.FC<{
  node: any
  eager: boolean
  className?: string
}> = (props) => {
  const { frontmatter, excerpt, id, wordCount, fields } = props.node
  const hasPage = wordCount.words > 0

  const image = (
    <a
      href={getSrc(frontmatter.image.highres)}
      className="workItem__image__link"
      data-summary={frontmatter.title + ":\n " + excerpt}
    >
      <GatsbyImage
        image={frontmatter.image.childImageSharp.gatsbyImageData}
        loading={props.eager ? "eager" : "lazy"}
        alt=""
      />
    </a>
  )

  return (
    <li className={classNames("workItem n-clear", props.className)} key={id}>
      <div className="workItem__side">
        {frontmatter.image ? (
          <div className="workItem__image">
            {frontmatter.frameUrl ? (
              <BrowserFrame url={frontmatter.frameUrl}>{image}</BrowserFrame>
            ) : (
              image
            )}
          </div>
        ) : null}
      </div>
      <div className="workItem__content">
        <div className="workItem__header n-spacing-bottom-small">
          <h2 className="n-color-accent">
            {hasPage ? (
              <Link to={fields.slug}>{frontmatter.title}</Link>
            ) : (
              frontmatter.title
            )}
            {frontmatter.published === false && <Unpublished />}
          </h2>
          <div className="n-color-mid n-type-small">{frontmatter.byline}</div>
        </div>

        <ReactMarkdown className="workItem__description n-vspacing-small">
          {frontmatter.excerpt.trim().replace("\n", "\n\n")}
        </ReactMarkdown>

        {frontmatter.CTALink ? (
          frontmatter.CTALink[0] === "/" ? (
            <Link
              className="button n-spacing-right-small"
              to={frontmatter.CTALink}
            >
              {frontmatter.CTA || `Read more…`}
            </Link>
          ) : (
            <a
              className="button n-spacing-right-small"
              href={frontmatter.CTALink}
              target="_blank"
              rel="noopener"
            >
              {frontmatter.CTA || `Visit ${frontmatter.title}`}
            </a>
          )
        ) : null}

        {hasPage ? (
          <Link className="button n-spacing-right-small" to={fields.slug}>
            Read more…
          </Link>
        ) : null}
      </div>
    </li>
  )
}
