import * as React from "react"
import { YTPlaylistItem } from "./YTTPlaylist"
import YouTube, { YouTubeProps } from "react-youtube"
import format from "date-fns/format"
import { TailSpin } from "react-loader-spinner"
import ReactPlaceholder from "react-placeholder"
import "react-placeholder/lib/reactPlaceholder.css"
import { times } from "lodash"
import "./YoutubePlaylist.scss"
import classNames from "classnames"

type globalState = "loading" | "error" | "ready"
interface SharedProps {
  theme?: string
  autoPlay?: boolean
}

interface SinglePlayerProps extends YouTubeProps, DateProps, SharedProps {
  // url?: string
  playlistItem?: YTPlaylistItem
  showDescription?: boolean
  showDate?: boolean
  /** This will override the state object. Useful when there is a containing
   * element that's fetching video information */
  globalState?: globalState
}

interface Props extends SharedProps {
  playlistId: string
  apiKey: string
  className?: string
  descriptionClassName?: string
  playlistItemClassName?: string
  playlistItemActiveClassName?: string
  showDate?: boolean
  /** Override the description */
  description: string
  continuous?: boolean
  dateClassName?: string
  mapPlaylistItems?: (i: YTPlaylistItem) => YTPlaylistItem
  descriptionWrapper?: React.ComponentType
}

type DateProps = { date?: string; dateClassName?: string }

interface DescriptionProps
  extends React.HTMLAttributes<HTMLDivElement>,
    DateProps {
  isLoading?: boolean
  descriptionWrapper?: React.ComponentType
}

interface PlaylistItemPlaceholderProps extends PlaylistItemSharedProps {
  isPlaceholder: true
}

interface PlaylistItemSharedProps {
  isActive: boolean
  activeClassName?: string
  className?: string
  isPlaceholder?: boolean
  item?: YTPlaylistItem
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface PlaylistItemProps extends PlaylistItemSharedProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  item: YTPlaylistItem
}

const PlaylistItem: React.FC<
  PlaylistItemProps | PlaylistItemPlaceholderProps
> = (props) => (
  <button
    type="button"
    style={
      props.className
        ? {
            appearance: "none",
          }
        : {
            appearance: "none",
            padding: 0,
            border: "none",
          }
    }
    className={classNames(
      "youtubePlaylistItem",
      props.className,
      props.isActive && props.activeClassName
    )}
    onClick={props.onClick}
    value={props.item?.id}
  >
    <div className="youtubePlaylistItem__inner" style={{ aspectRatio: "16/9" }}>
      <ReactPlaceholder
        type="rect"
        style={{ flexGrow: 1, height: "100%" }}
        ready={!props.isPlaceholder}
        showLoadingAnimation
      >
        <img
          src={props.item?.snippet.thumbnails.medium?.url}
          style={{
            height: "100%",
            width: "auto",
          }}
        />
      </ReactPlaceholder>
    </div>

    <div
      style={{
        flexShrink: 0,
        position: "relative",
        height: "1.5em",
        width: "100%",
      }}
    >
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          flexGrow: 0,
          width: "100%",
          textAlign: "left",
          position: "absolute",
          inset: 0,
        }}
        title={props.item?.snippet.title}
      >
        <ReactPlaceholder
          type="textRow"
          ready={!props.isPlaceholder}
          showLoadingAnimation
        >
          {props.item?.snippet.title}
        </ReactPlaceholder>
      </div>
    </div>
  </button>
)

const YoutubeDescription: React.FC<DescriptionProps> = ({
  date,
  dateClassName,
  isLoading,
  descriptionWrapper,
  ...props
}) => (
  <caption
    {...props}
    style={{
      whiteSpace: descriptionWrapper ? undefined : "pre-line",
      ...props.style,
    }}
  >
    <ReactPlaceholder type="text" ready={!isLoading} showLoadingAnimation>
      {descriptionWrapper
        ? React.createElement(descriptionWrapper, {}, props.children)
        : props.children}

      {date && (
        <div
          className={dateClassName}
          style={
            dateClassName ? undefined : { fontWeight: "bolder", marginTop: 10 }
          }
        >
          Published {format(new Date(date), "PPPP")}
        </div>
      )}
    </ReactPlaceholder>
  </caption>
)

const loadingMessages: Record<globalState, string | undefined> = {
  loading: "Fetching video info…",
  error: "Something went wrong…",
  ready: undefined,
}

export const YoutubePlayerWithDescription: React.FC<SinglePlayerProps> = ({
  playlistItem,
  showDescription = true,
  showDate = true,
  ...props
}) => {
  const snippet = playlistItem?.snippet
  const [loadingState, setLoadingState] = React.useState<globalState>("loading")

  const state: globalState =
    props.globalState === "ready"
      ? loadingState
      : props.globalState ?? loadingState

  React.useEffect(() => {
    setLoadingState("loading")
  }, [playlistItem])

  return (
    <div style={props.style}>
      <div
        style={{
          background: "black",
          flex: "1 1 auto",
          position: "relative",
        }}
      >
        {playlistItem ? (
          <YouTube
            videoId={playlistItem?.contentDetails.videoId}
            key={playlistItem?.contentDetails.videoId}
            iframeClassName="youtubeIframe"
            className="youtubeContainer"
            onReady={() => setLoadingState("ready")}
            opts={{
              host: "https://www.youtube-nocookie.com",
              playerVars: {
                modestbranding: 1,
                rel: 0,
                autoplay: props.autoPlay ? 1 : 0,
              },
            }}
            {...props}
          />
        ) : (
          <div
            style={{
              aspectRatio: "16/9",
              width: "100%",
            }}
          />
        )}

        {state !== "ready" && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              backgroundColor: "black",
              flexDirection: "column",
              justifyContent: "center",
              backgroundImage: snippet
                ? `url("${
                    (snippet.thumbnails.maxRes ?? snippet.thumbnails.high).url
                  }")`
                : undefined,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
            }}
          >
            <div
              style={{
                background: "rgba(0,0,0,0.8)",
                padding: 20,
                color: "white",
                textAlign: "center",
                borderRadius: 10,
              }}
            >
              {state === "loading" && (
                <TailSpin
                  color={props.theme ?? "white"}
                  wrapperStyle={{ margin: "0 auto 20px", display: "block" }}
                />
              )}

              {loadingMessages[state]}
            </div>
          </div>
        )}
      </div>

      {showDescription && (
        <YoutubeDescription
          date={
            showDate ? playlistItem?.contentDetails.videoPublishedAt : undefined
          }
        >
          {snippet?.description}
        </YoutubeDescription>
      )}
    </div>
  )
}

export const YoutubePlaylist = React.memo<Props>(
  ({ showDate = true, ...props }) => {
    const [playlistData, setPlaylistData] = React.useState<YTPlaylistItem[]>()
    const [currentVideo, setCurrentVideo] = React.useState<YTPlaylistItem>()
    const [autoPlay, setAutoPlay] = React.useState(props.autoPlay)
    const [state, setState] = React.useState<globalState>("loading")
    const [hasJs, setHasJs] = React.useState(false)

    const handleSetVideo = React.useCallback<
      React.MouseEventHandler<HTMLButtonElement>
    >(
      (e) => {
        const playlistItemId = e.currentTarget.value
        const targetVideo = playlistData?.find((i) => i.id === playlistItemId)
        if (targetVideo) {
          setAutoPlay(true)
          setCurrentVideo(targetVideo)
        }
      },
      [playlistData]
    )

    const handleOnEnd = React.useCallback(() => {
      const currentIndex = playlistData?.findIndex((i) => i === currentVideo)
      if (
        !props.continuous ||
        typeof currentIndex === "undefined" ||
        currentIndex === -1 ||
        !playlistData
      )
        return

      const newVideo = playlistData[currentIndex + 1]
      if (newVideo) {
        setTimeout(() => {
          setAutoPlay(true)
          setCurrentVideo(newVideo)
        }, 1500)
      }
    }, [currentVideo, playlistData])

    React.useEffect(() => {
      setHasJs(true)
      fetch(
        `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails%2Cstatus&maxResults=50&playlistId=${props.playlistId}&key=${props.apiKey}`
      )
        .then((response) => response.json())
        .then((response: { items: YTPlaylistItem[] }) => {
          let items = response.items.filter(
            (i) =>
              i.status.privacyStatus !== "private" &&
              i.status.privacyStatus !== "privacyStatusUnspecified"
          )

          if (props.mapPlaylistItems) items = items.map(props.mapPlaylistItems)

          setPlaylistData(items)
          setState("ready")
          setCurrentVideo(items[0])
        })
        .catch((e) => {
          console.log(e)
          setState("error")
        })
    }, [props.playlistId, props.apiKey])

    return (
      <div className={props.className}>
        <noscript>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube-nocookie.com/embed/videoseries?list=${props.playlistId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </noscript>

        {hasJs && (
          <>
            <div className="youtubeOuterContainer" style={{ gap: 10 }}>
              <YoutubePlayerWithDescription
                style={{ flexGrow: 1 }}
                autoPlay={autoPlay}
                playlistItem={currentVideo}
                showDescription={false}
                onEnd={handleOnEnd}
                theme={props.theme}
                globalState={state}
              />

              <div className="youtubePlaylistContainer">
                <div className="youtubePlaylist">
                  {playlistData
                    ? playlistData.map((i) => (
                        <PlaylistItem
                          item={i}
                          onClick={handleSetVideo}
                          className={props.playlistItemClassName}
                          isActive={i === currentVideo}
                          activeClassName={props.playlistItemActiveClassName}
                          key={i.id}
                        />
                      ))
                    : times(10, (i) => (
                        <PlaylistItem
                          className={props.playlistItemClassName}
                          isPlaceholder
                          isActive={false}
                          key={i}
                        />
                      ))}
                </div>
              </div>
            </div>

            <YoutubeDescription
              style={{ paddingTop: 20, minHeight: "3em", display: "block" }}
              className={props.descriptionClassName}
              date={
                showDate
                  ? currentVideo?.contentDetails.videoPublishedAt
                  : undefined
              }
              dateClassName={props.dateClassName}
              isLoading={state !== "ready"}
              descriptionWrapper={props.descriptionWrapper}
            >
              {props.description ?? currentVideo?.snippet.description}
            </YoutubeDescription>
          </>
        )}
      </div>
    )
  }
)
