import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Audio } from "./Markdown"
import { GatsbyImage } from "gatsby-plugin-image"

/** basically the same as projecthighlights */
export const MusicHighlights: React.FC = (props) => {
  const { projects } = useStaticQuery(graphql`
    query ($publishedFilter: BooleanQueryOperatorInput) {
      projects: allMdx(
        filter: {
          fields: {
            contentType: { eq: "music" }
            slug: {
              in: [
                "/music/campfire-concerto"
                "/music/forest-creek"
                "/music/ambient-guitar"
              ]
            }
          }
          frontmatter: { published: $publishedFilter }
        }
      ) {
        edges {
          node {
            id

            fields {
              slug
            }
            frontmatter {
              title
              audio
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 372
                    layout: CONSTRAINED
                    quality: 98
                    aspectRatio: 1
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="n-type-small n-spacing-bottom-medium g-popout n-text-centered">
      <ul className="grid gap-3">
        {projects.edges.map(({ node }, i) => (
          <li
            className="n-background-white shadow overflow-hidden relative"
            key={node.id}
          >
            <Link
              to={node.fields.slug}
              key={node.id}
              title={node.frontmatter.title}
            >
              <GatsbyImage
                image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                loading={"lazy"}
                className="n-padding-tiny w-full"
                objectFit="cover"
                alt=""
              />
            </Link>
            <Audio
              src={node.frontmatter.audio}
              className=" unstyled"
              backgroundColor="transparent"
            />
          </li>
        ))}
      </ul>
      <Link className="button n-spacing-top-small" to="/music">
        More music
      </Link>
    </div>
  )
}
