/** @format */
import React from "react"
import { MarkdownBrowserFrame } from "./BrowserFrame"
import { PhoneFrame } from "./PhoneFrame"
import { Caption, FigCaption } from "./Caption"
import {
  BlockQuote,
  UL,
  H1,
  H2,
  H3,
  OL,
  HR,
  Pre,
  Audio,
  Aside,
} from "./Markdown"
import { TikTok, SoundCloud, Vimeo, YouTube } from "mdx-embed"
import { YoutubePlaylist } from "./YoutubePlaylist"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { ContentFilterSwitcher, FilteredContent } from "./ContentFilter"
import { ClientOnly } from "./ClientOnly"
import { Avatar } from "./Avatar"
import { AboutTeaser } from "./AboutTeaser"
import * as youtubeDescriptionHelper from "../youtubeDescriptionHelper"
import { ProjectHighlights } from "./ProjectHighlights"
import { MusicHighlights } from "./MusicHighlights"
import { IYouTubeProps } from "mdx-embed/dist/types/components/youtube/youtube"

/** Wraps the description in markdown */
const YoutubeDescriptionWrapper: React.FC<{ children?: string }> = ({
  children,
}) =>
  children ? (
    <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]}>
      {children}
    </ReactMarkdown>
  ) : null

export const shortcodes = {
  BrowserFrame: MarkdownBrowserFrame,
  PhoneFrame,
  Caption,
  figcaption: FigCaption,
  blockquote: BlockQuote,
  ul: UL,
  ol: OL,
  h1: H1,
  h2: H2,
  h3: H3,
  hr: HR,
  FilteredContent: (props) => {
    return <FilteredContent {...props} for={props.htmlFor ?? props.for} />
  },
  TikTok,
  SoundCloud: (props) => (
    <div className="n-vspacing-medium">
      <SoundCloud {...props} />
    </div>
  ),
  iframe: (props) => <iframe className="n-vspacing-medium" {...props} />,
  ClientOnly,
  Vimeo,
  YouTube: ({
    children,
    ...props
  }: IYouTubeProps & { children?: React.ReactNode }) => (
    <figure className="n-vspacing-medium">
      <YouTube {...props} />
      {children && <FigCaption>{children}</FigCaption>}
    </figure>
  ),
  Aside,
  AboutTeaser,
  ProjectHighlights,
  MusicHighlights,
  pre: Pre,
  audio: Audio,
  Avatar,
  ContentFilterSwitcher: (props) => (
    <ContentFilterSwitcher className="n-vspacing-medium" {...props} />
  ),
  YoutubePlaylist: (props) => (
    <YoutubePlaylist
      apiKey="AIzaSyA2hEVZt8F5ZyfY9ow25TgDzsQVBH_C-qQ"
      className="n-type-small n-background-barelyThere g-popout shadow"
      playlistItemClassName="n-type-smallest n-background-transparent appearance-none n-padding-none"
      playlistItemActiveClassName="n-color-accent"
      descriptionClassName="n-padding-small max-h text-left w-full"
      theme="#4f8bde"
      dateClassName="n-spacing-top-small n-color-mid"
      mapPlaylistItems={youtubeDescriptionHelper}
      {...props}
      continuous
      descriptionWrapper={YoutubeDescriptionWrapper}
    />
  ),
  undefined: (props) => <>{props.children}</>,
}
