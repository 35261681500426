import classNames from "classnames"
import React from "react"

interface Props {
  url: string
  caption?: string
  interactive?: boolean
  children?: any
  hasBorder?: boolean
}

export const BrowserFrame: React.FC<Props> = ({
  url,
  caption,
  interactive,
  children,
  hasBorder,
}) => {
  return (
    <figure>
      <div className="browserFrame" role="presentation">
        <div className="browserFrame__icons">
          <div className="browserFrame__icon"></div>
          <div className="browserFrame__icon"></div>
          <div className="browserFrame__icon"></div>
        </div>
        {url && url.trim().length ? (
          <div className="browserFrame__search n-color-light" data-url={url} />
        ) : null}
      </div>

      <div
        className={`${interactive ? "interactiveFrame" : ""} ${
          hasBorder ? "browserFrame__border" : ""
        }`}
      >
        {children}
      </div>

      {caption ? (
        <figcaption className="n-type-smallest n-text-centered n-color-mid n-vspacing-tiny">
          {caption}
        </figcaption>
      ) : null}
    </figure>
  )
}

export const MarkdownBrowserFrame: React.FC<
  Props & {
    className?: string
    small?: boolean
  }
> = ({ small = true, ...props }) => (
  <figure
    className={classNames(
      "n-vspacing-medium",
      small ? "caseImage" : undefined,
      props.className || ""
    )}
  >
    <BrowserFrame {...props} />
  </figure>
)
