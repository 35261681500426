import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React, { CSSProperties } from "react"

const avatarStyle: CSSProperties = {
  borderRadius: "100%",
  overflow: "hidden",
  clipPath: "border-box",
  height: "auto",
}

const transformOptions: ISharpGatsbyImageArgs["transformOptions"] = {
  // duotone: {
  //   highlight: "#ffffff",
  //   shadow: "#4f8bde",
  //   opacity: 60,
  // },
}

export const Avatar: React.VFC<{ className?: string }> = (props) => (
  <StaticImage
    className={props.className ?? "n-vspacing-medium"}
    style={avatarStyle}
    layout="constrained"
    width={150}
    loading="eager"
    alt=""
    src={"../images/avatar.jpg"}
    transformOptions={transformOptions}
  />
)

/** Because of the limitations of staticImage, we need to make this a separate component */
export const SmallAvatar: React.VFC<{ className?: string }> = (props) => (
  <StaticImage
    className={props.className ?? "n-vspacing-medium"}
    style={avatarStyle}
    layout="constrained"
    width={40}
    loading="eager"
    alt=""
    src={"../images/avatar.jpg"}
    transformOptions={transformOptions}
  />
)
