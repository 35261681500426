import classNames from "classnames"
import React from "react"
import { ReactNode } from "react"
import { Aside } from "./Markdown"
import { contentFilterFromUrl } from "./AutoContentFilter"

export type contentFilters = "webdev" | "sound" | "all"

export interface ContentFilterContextType {
  contentFilter: contentFilters
  setContentFilter: (newFilter: contentFilters) => void
}

export const ContentFilterContext =
  React.createContext<ContentFilterContextType>({
    contentFilter: "all",
    setContentFilter: () => {},
  })

export const ContentFilterProvider: React.FC<{
  children: React.ReactNode
  uri: string
}> = (props) => {
  const [contentFilter, setContentFilter] = React.useState<contentFilters>(
    contentFilterFromUrl(props.uri)
  )

  const state = React.useMemo<ContentFilterContextType>(
    () => ({
      contentFilter,
      setContentFilter,
    }),
    [contentFilter, setContentFilter]
  )

  return (
    <ContentFilterContext.Provider value={state}>
      {props.children}
    </ContentFilterContext.Provider>
  )
}

export interface FilteredContentProps {
  children: ReactNode
  for: contentFilters
  /** don't show this for 'all' */
  exclusive?: boolean
}

export const FilteredLI: React.FC<FilteredContentProps> = (props) => (
  <FilteredContent for={props.for} exclusive={props.exclusive}>
    <li>{props.children}</li>
  </FilteredContent>
)

export const FilteredContent: React.FC<FilteredContentProps> = (props) => {
  const { contentFilter } = React.useContext(ContentFilterContext)
  const show =
    contentFilter === props.for || (!props.exclusive && contentFilter === "all")

  if (!props.for) {
    console.log("props.for is blank!", props.children)
    return null
  }

  return show ? <>{props.children}</> : null
}

export const ContentFilterSwitcher: React.FC<{ className?: string }> = (
  props
) => {
  const { contentFilter, setContentFilter } =
    React.useContext(ContentFilterContext)

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => setContentFilter(e.currentTarget.value as contentFilters), [])

  return (
    <Aside
      className={classNames(
        "n-color-semi font-medium n-type-small flex-switch items-center gap-2 justify-center n-block-centered w-max-content",
        props.className
      )}
    >
      <div className="font-bold">Show content relevant to:</div>
      <label className="cursor-pointer">
        <input
          type="radio"
          name="contentFilter"
          value="all"
          checked={contentFilter === "all"}
          onChange={handleChange}
        />
        Everything
      </label>
      <label className="cursor-pointer">
        <input
          type="radio"
          name="contentFilter"
          value="sound"
          checked={contentFilter === "sound"}
          onChange={handleChange}
        />
        Sound Design
      </label>
      <label className="cursor-pointer">
        <input
          type="radio"
          name="contentFilter"
          value="webdev"
          checked={contentFilter === "webdev"}
          onChange={handleChange}
        />
        Web Development
      </label>
    </Aside>
  )
}
