import React from "react"
import { Link } from "gatsby"
import { MHPageProps } from "../types"
import "../graphQLFragments"
import { ClientOnly } from "./ClientOnly"
import { Avatar, SmallAvatar } from "./Avatar"
import classNames from "classnames"

export const Layout = React.memo<MHPageProps>((props) => {
  const { location, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  const [showEmail, setShowEmail] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const lastScrollState = React.useRef(false)

  React.useEffect(() => {
    setShowEmail(true)
    const handleScroll = () => {
      const evaluation = window.scrollY > 100
      if (evaluation !== lastScrollState.current) {
        setScrolled(evaluation)
      }

      lastScrollState.current = evaluation
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scrol", handleScroll)
  }, [])

  const isIndex = location.pathname === rootPath

  return (
    <>
      <div className={classNames("container", "n-background-background")}>
        <div aria-hidden="true" className={classNames("gradientBg")}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>

        <div className="topContentWrapper">
          <header className="header">
            <div className="n-vpadding-small header__sticky">
              <div className="contentWrapper n-hspacing-medium header__main nowrap">
                <Link
                  to="/"
                  className={classNames(
                    "header__logo",
                    isIndex && "header__logo--hidden"
                  )}
                >
                  <SmallAvatar className="header__logo__avatar" />
                  <div className="header__logoText">Marco Hamersma</div>
                </Link>

                <nav className="navigation">
                  <ul
                    className={classNames(
                      "navigationList headerBg",
                      !scrolled && "headerBg__hidden"
                    )}
                  >
                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem"
                        to="/posts"
                      >
                        Posts
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem nowrap"
                        to="/work"
                      >
                        Web Dev
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem"
                        to="/sound"
                      >
                        Sound
                      </Link>
                    </li>

                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem"
                        to="/now"
                      >
                        Now
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem hideOnMobile"
                        to="/pinboard"
                      >
                        Pinboard
                      </Link>
                    </li>

                    <li>
                      <Link
                        activeClassName="active"
                        className="n-color-mid navigationItem nowrap"
                        to="/about"
                      >
                        About me
                      </Link>
                    </li>
                    <li>
                      <a
                        className="n-color-mid navigationItem hideOnMobile"
                        href={
                          showEmail
                            ? "mailto:website@marcohamersma.com?subject=Hi!"
                            : undefined
                        }
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            {isIndex && (
              <div
                className={`contentWrapper n-hspacing-medium n-padding-top-huge header__intro`}
              >
                <Avatar className="shrink-0" />
                <div>
                  <div className="n-type-h1 n-spacing-bottom-tiny">
                    Hi! I'm Marco Hamersma
                  </div>
                  <div className="n-type-h2 n-color-semi">
                    I'm a&nbsp;
                    <Link className="g-link" to="/work">
                      web developer
                    </Link>
                    &nbsp;&&nbsp;
                    <Link className="g-link" to="/sound">
                      sound designer
                    </Link>
                    .
                  </div>
                </div>
              </div>
            )}
          </header>

          <main
            id="mainContentWrapper"
            className="contentWrapper n-hspacing-medium n-vspacing-large n-clear"
          >
            {children}
          </main>
        </div>

        <footer className="n-background-black n-color-white n-vpadding-small g-thicker z-1">
          <div className="contentWrapper n-hspacing-medium footer__content">
            <div className="n-spacing-right-small">
              I’m @marcohamersma on&nbsp;
              <a rel="me" href="https://toot.cafe/@marcohamersma">
                Mastodon
              </a>
              ,&nbsp;
              <a rel="me" href="https://bsky.app/profile/marcohamersma.com">
                BlueSky
              </a>
              ,&nbsp;
              <a rel="me" href="https://github.com/marcohamersma">
                GitHub
              </a>
              , and a bunch of other places. Don’t be afraid to say hi
              <ClientOnly>
                <>
                  {" "}
                  at&nbsp;
                  <a href="mailto:website@marcohamersma.com?subject=Hi!">
                    website@marcohamersma.com
                  </a>
                </>
              </ClientOnly>
              .&nbsp;
            </div>
            <div className="footer__copyright">
              <a href="/rss.xml" className="g-link g-link-on-black">
                <span className="n-small-caps">RSS</span> Feed
              </a>
              &nbsp;·&nbsp;
              <Link className="g-link g-link-on-black" to="/website">
                Copyright &amp; site info
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
})

export default Layout
