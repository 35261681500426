import { PageProps } from "gatsby"
import React from "react"
import { ContentFilterContext, contentFilters } from "./ContentFilter"

export const contentFilterFromUrl = (uri: string): contentFilters => {
  if (uri === "/work" || uri === "/opportunities") {
    return "webdev"
  } else if (uri === "/sound") {
    return "sound"
  }

  return "all"
}

/** Automatically sets the content filter based on initial page loaded or
 * overrides in the Location state */
export const AutoContentFilter: React.FC<
  PageProps<unknown, unknown, { contentFilter: contentFilters } | undefined>
> = (props) => {
  const { setContentFilter } = React.useContext(ContentFilterContext)
  const stateOverride = props.location.state?.contentFilter
  // TODO: merge all of this in the contentfilter context.
  const updateContentFilter = React.useCallback(
    (filter: contentFilters, reason = "initial page load") => {
      if (process.env.NODE_ENV === "development")
        console.log(
          `auto-setting content filter to "${filter}" due to ${reason}`
        )

      setContentFilter(filter)
    },
    []
  )

  React.useEffect(() => {
    if (stateOverride)
      updateContentFilter(stateOverride, "override through Link button")
  }, [stateOverride])

  return null
}
