import * as React from "react"
export const Caption: React.FC<any> = ({ children, As = "p", className }) => (
  <As
    className={`n-type-smallest n-color-mid n-spacing-bottom-medium n-spacing-top-smaller n-text-centered ${
      className || ""
    }`}
  >
    {children}
  </As>
)

export const FigCaption: typeof Caption = (props) => (
  <Caption {...props} className="n-hspacing-medium" As="figcaption" />
)
