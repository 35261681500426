import React from "react"

export const PhoneFrame: React.FC = ({ children }) => {
  return (
    <figure className="n-vspacing-medium caseImage">
      <div className="phoneFrame n-background-black">
        <div className="phoneFrame__speaker n-background-dark"></div>
      </div>

      {children}

      <div className="phoneFrame__bottom n-background-black ">
        <div className="phoneFrame__homeButton n-background-dark"></div>
      </div>
    </figure>
  )
}
